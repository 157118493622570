<template>
  <div class="dashboardHelp appMargin">
    <!-- <div class="dashboardHelp__center__title">Financial Education</div> -->
    <!-- <p class="dashboardHelp__center__description">
      68% of guardians struggle setting chores and introducing money management
      to their children.
    </p> -->

    <div v-if="youtubeVideosGetter.length > 0" style="width: 100%">
      <div class="scheduler-grid">
        <div
          v-for="item in youtubeVideosGetter"
          :key="item.title"
          class="youtubeCard"
        >
          <img
            class="youtubeCard__image"
            :src="item.picture"
            :alt="item.title"
            @click="openYoutubeModal(item.url)"
          />

          <div class="youtubeCard__content">
            <h3 @click="openYoutubeModal(item.url)">
              {{ item.title }}
            </h3>
            <div class="youtubeCard__content__likes">
              <div
                v-if="item.your_choice == 'like'"
                class="youtubeCard__content__likes__item"
                style="margin-right: 25px"
              >
                <button @click="likeYoutubeAction(item.id)">
                  <svg
                    width="18"
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#fe5079"
                    viewBox="0 0 24 24"
                    stroke="#fe5079"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                    />
                  </svg>
                </button>
                <span style="color: #fe5079">
                  {{ item.total_like }}
                </span>
              </div>
              <div
                v-else
                class="youtubeCard__content__likes__item"
                style="margin-right: 25px"
              >
                <button @click="likeYoutubeAction(item.id)">
                  <svg
                    width="18"
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                    />
                  </svg>
                </button>
                <span>
                  {{ item.total_like }}
                </span>
              </div>
              <div
                v-if="item.your_choice == 'dislike'"
                class="youtubeCard__content__likes__item"
              >
                <button @click="dislikeYoutubeAction(item.id)">
                  <svg
                    width="18"
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#fe5079"
                    viewBox="0 0 24 24"
                    stroke="#fe5079"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"
                    />
                  </svg>
                </button>
                <span style="color: #fe5079">
                  {{ item.total_dislike }}
                </span>
              </div>
              <div v-else class="youtubeCard__content__likes__item">
                <button @click="dislikeYoutubeAction(item.id)">
                  <svg
                    width="18"
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"
                    />
                  </svg>
                </button>
                <span>
                  {{ item.total_dislike }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="noFinancialFound shadow"
      style="background: white; color: var(--secondary); padding: 25px 0"
    >
      No videos found
    </div>

    <AppYoutubeModal :is-open="youtubeModal" @close="closeYoutubeModal">
      <iframe
        title="Video"
        style="width: 95vw"
        height="300"
        :src="selectedVideo"
        allow="autoplay; encrypted-media"
        allowfullscreen
      />
    </AppYoutubeModal>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import axios from "axios";
  import status from "../../utils/statusCode";

  export default {
    data() {
      return {
        modal: false,
        message: "",
        selectedVideo: "",
        youtubeModal: false,
        isLoading: true,
      };
    },
    methods: {
      ...mapActions([
        "likeYoutubeAction",
        "dislikeYoutubeAction",
        "TOAST",
      ]),
      openYoutubeModal(video) {
        this.selectedVideo = video;
        this.youtubeModal = true;
      },
      closeYoutubeModal() {
        this.youtubeModal = false;
        this.selectedVideo = "";
      },
      async fetchYouTubeVideosAction() {
        const token = localStorage.getItem("guardian_token");
        const userId = localStorage.getItem("guardian_user_id");
        const family = localStorage.getItem("guardian_family_id");

        await axios
          .get(`/youtube/index`, {
            params: {
              token: token,
              userId: userId,
              family_id_check: family,
            },
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(response.data.statusCode)) {
              this.$store.commit("FETCH_YOUTUBE_VIDEOS", response.data.data);
            } else if (status.invalidToken(statusCode)) {
              this.deleteStorage();
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    computed: {
      ...mapGetters(["youtubeVideosGetter"]),
    },
    mounted() {
      Promise.all([this.fetchYouTubeVideosAction()]).then(() => {
        this.$store.state.globaLoading = false;
      });
    },
  };
</script>

<style lang="scss" scoped>
  .dashboardHelp__center__title {
    display: block;
    line-height: 20px;
    font-weight: 700;
    font-size: 20px;
    color: var(--secondary);
    text-align: center;
    margin-bottom: 5px;
  }
  .dashboardHelp__center__description {
    display: block;
    font-size: 12px;
    color: var(--secondary);
    text-align: center;
    line-height: 17px;
  }

  .noFinancialFound {
    background: var(--gray);
    border-radius: 10px;
    padding: 15px 25px;
    margin-top: 30px;
    font-size: 14px;
    color: var(--blueish);
    text-align: center;
  }

  .youtubeCard {
    width: 100%;
    cursor: pointer;
    position: relative;

    &__content {
      margin-top: 10px;
      color: var(--secondary);

      h3 {
        font-weight: 800;
        font-size: 15px;
        color: #01A4C0;
      }

      &__likes {
        display: flex;
        margin-top: 5px;

        &__item {
          display: flex;

          button {
            border: none;
            background: transparent;
            font-weight: 700;
            text-transform: uppercase;
            margin-right: 5px;
            color: var(--secondary);
          }

          span {
            font-weight: 500;
          }
        }
      }
    }

    &__image {
      position: relative;
      width: 100%;
      max-height: 300px;
      overflow: hidden;
      // border-radius: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
    }
  }

  @media screen and (min-width: 768px) {
    .youtubeCard {
      width: 100%;
      max-width: 350px;
    }
  }
</style>
